<template>
    <div class="mr-3">
        <span v-for="(item,index) in items" :key="index">
            <span class="list-group-item">
                {{ item.name }}
                <i class="pe-7s-close mr-3 float-left cursor-pointer text-danger" @click="deleteCategory(item.id)"></i>
                <i class="fa fa-edit float-left cursor-pointer text-primary" @click="editCategory(item.index, item)"></i>
            </span>
            <category-tree :items="item.all_children" :edit="$props.edit" ></category-tree>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'category-tree',
        props: [ 'items', 'edit' , 'url'],
        mounted() {
            this.recursion++;
        },
        methods: {
            editCategory(index, item) {
                this.$root.$emit('bv::hide::modal', 'edit-modal');
                this.$props.edit(index, item);
            },
            // deleteCategory(index, item) {
            //     this.$props.delete(index, item);
            // },
            deleteCategory(id) {
                window.swal({
                        title: "آیتم حذف شود؟",
                        text: "این عملیات غیر قابل بازگشت خواهد بود",
                        icon: "warning",

                        showCancelButton: true,
                        showConfirmButton: true,
                        dangerMode: true,
                        buttons: ["خیر", "بله حذف کن"]
                    })
                    .then((willDelete) => {
                        if (willDelete) {
                            this.$axios.delete(this.$root.baseUrl +'/api/admin/categories/' + id)
                                .then((response) => {
                                    this.$props.items = this.$props.items.filter(item => item.id != id)
                                    window.swal({
                                        icon: "success",
                                        showConfirmButton: true,
                                        text: response.data.message,
                                        button: "باشه",
                                    })
                                })
                                .catch(error => {
                                    window.swal({
                                        title: 'خطا',
                                        showConfirmButton: true,
                                        text: error.response.data.message,
                                        button: "باشه",


                                    })
                                })
                        }
                    })
                    .catch(() => {

                    })
            },
            // deleteCategory (index) {
               
            //     window.swal({
            //         title: 'آیتم حذف شود؟',
            //         type: 'warning',
            //         text: 'این عملیات غیر قابل بازگشت خواهد بود',
            //         showCancelButton: true,
            //         confirmButtonClass: 'btn-warning',
            //         cancelButtonClass: 'btn-danger',
            //         confirmButtonText: 'بله حذف کن',
            //         cancelButtonText: 'خیر',
            //         disableButtonsOnConfirm: true
            //     },
            //     () => {
            //         const item = window.clone(this.$props.items[index])

            //         window.$.post(this.$props.url + '/' + item.id, { _method: 'DELETE' }, response => {
            //         if (response.success) {
            //             this.$root.$delete( this.$props.items, index)
            //         }
            //         }).fail(function (xhr, status, error) {
            //         window.swal(xhr.responseJSON.message)
            //         })
            //     })
            // }
        },
        data() {
            return {
                recursion: 0
            }
        }
    }
</script>